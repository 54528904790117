import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Bloglist from "../../../components/bloglist/bloglist"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'

import Youtubeembed from "../../../components/youtubeembed/youtubeembed";

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/oceneni-v-ramci-european-customer-centricity-awards.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Ocenění v rámci European Customer Centricity Awards" description="You’ve probably heard this before: Now that life is slowly returning to “normal,” people aren’t scrambling to get back to the office." />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Ocenění v rámci European Customer Centricity Awards</h1>
			<div className="ftrs-blogpost__date">15.10.2021</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Ocenění v rámci European Customer Centricity Awards" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>
				
				<p>Mezinárodní konzultantská společnost Arcet Global, která pomáhá organizacím dosahovat dlouhodobého úspěchu, organizuje European Customer Centricity Awards, kde naše aplikace <a className="ftrs-blacklink" href="https://www.feedtrack.eu" target="_blank" rel="noreferrer">feedTRACK</a> zapojená v&nbsp;řešení Moneta&nbsp;Money&nbsp;Bank získala ocenění v&nbsp;kategorii „Nejlepší měření v&nbsp;oblasti zákaznické zkušenosti“ ocenění „Highly commended“.</p>

				<h3 className="ftrs-h3 mt-4 mb-4">European Customer Centricity Awards na&nbsp;sociálních sítích:</h3>

				<a aria-label="Linkedin" className="ftrs-socialicon ftrs-socialicon--linkedin" href="https://www.linkedin.com/company/arcetglobal" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faLinkedinIn} />
				</a>
				<a aria-label="Facebook" className="ftrs-socialicon ftrs-socialicon--facebook ml-3" href="https://www.facebook.com/ARCETGlobal" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faFacebookF} />
				</a>
				<a aria-label="Instagram" className="ftrs-socialicon ftrs-socialicon--instagram ml-3" href="https://www.instagram.com/arcetglobal_" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faInstagram} />
				</a>
				<a aria-label="Instagram" className="ftrs-socialicon ftrs-socialicon--twitter ml-3" href="https://twitter.com/arcetglobal" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faTwitter} />
				</a>

				<h2 className="ftrs-h2 mt-5 mb-4">Představení European Customer Centricity Awards</h2>

				<Row>
					<Col xs="12" lg="6" className="mb-5">
						<Youtubeembed embedId="TmymYiDnKhc" />
					</Col>
					<Col xs="12" lg="6" className="mb-5">
						<Youtubeembed embedId="7Xw5AT0soqM" />
						<p className="mt-3">Čím se organizace zabývá se můžete dozvědět například v&nbsp;záznamu webináře z&nbsp;českého prostředí</p>
					</Col>
				</Row>
				

				<h2 className="ftrs-h2 mb-4">feedTRACK - Inteligentní řízení zákaznické zkušenosti</h2>

				<Youtubeembed embedId="6P0qX8FV_4I" />

				<p className="mt-5">feedTRACK je online aplikace, která sbírá a&nbsp;analyzuje zpětnou vazbu. Poskytuje přehledné reporty. Zajistí, že se podnět ihned dostane ke&nbsp;správným lidem ve&nbsp;firmě a&nbsp;bude efektivně zpracován.</p>
				<p>Aplikaci využívají společnosti jako jsou Moneta&nbsp;Money&nbsp;Bank, Slovenská&nbsp;spořitelna,T-Mobile, Uniqa a&nbsp;spousta dalších.</p>

				<h3 className="ftrs-h3 mt-5 mb-4">Hlavní výhody aplikace feedTRACK</h3>

				<p>Naše dlouholeté zkušenosti s&nbsp;prací se&nbsp;zákazníky jsme přetavili do&nbsp;jedinečného nástroje na&nbsp;bázi špičkových technologií. A&nbsp;stále inovujeme.</p>

				<ul>
					<li>
						<h4 className="ftrs-h4">Jednoduché nastavení a&nbsp;bezstarostný provoz</h4>
						<p>Zajistíme rychlé nastavení a&nbsp;postaráme se o&nbsp;bezchybný sběr a&nbsp;zpracování zpětné vazby. Vy se díky tomu můžete plně věnovat zlepšování zákaznického zážitku.</p>
					</li>
					<li>
						<h4 className="ftrs-h4">Vysoké zapojení zákazníků a&nbsp;návratnost</h4>
						<p>Neustále zdokonalujeme způsoby, jak zákazníky oslovit. Díky tomu máme vysoké procento odpovědí.</p>
					</li>
					<li>
						<h4 className="ftrs-h4">Velký výběr funkcí a&nbsp;flexibilita</h4>
						<p>Systém feedTRACK je moderní, ověřené a&nbsp;zároveň flexibilní řešení, které lze snadno přizpůsobit vašim potřebám.</p>
					</li>
					<li>
						<h4 className="ftrs-h4">Praktické nástroje pro follow-up</h4>
						<p>Sběrem dat to teprve začíná. feedTRACK nabízí řadu praktických nástrojů pro&nbsp;zpracování podnětů od&nbsp;zákazníků, takže zákaznickou zkušenost můžete ihned začít řídit a&nbsp;zlepšovat.</p>
					</li>
					<li>
						<h4 className="ftrs-h4">Síla zkušeností a&nbsp;neustálá inovace</h4>
						<p>Za feedTRACKem stojí projektový tým s&nbsp;dlouholetými zkušenostmi. Neusínáme na&nbsp;vavřínech a&nbsp;neustále jej zdokonalujeme tak, aby vyhovoval náročným požadavkům moderní doby.</p>
					</li>
				</ul>

			</Container>
		</div>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog